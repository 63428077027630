<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2 mt-2">
              <div class="col text-center">
                <img
                  src="@/assets/images/brand/navy-gold-med.png"
                  alt="Swandoola"
                  width="100"
                />
              </div>
            </div>
            <div class="row">
              <div class="col text-center">
                <h2>Swandoola Practitioners</h2>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col">
                <h4 class="mb-3">Clinic Details</h4>
              </div>
            </div>
            <form @submit.prevent="submitForm">
              <div class="row" :class="form.clinic_name ? 'mb-3' : 'mb-4'">
                <div class="col">
                  <input
                    type="text"
                    v-model="form.clinic_name"
                    class="form-control"
                    placeholder="Clinic name (optional)"
                    v-tooltip:bottom="
                      'Your clinic\'s name as it should be displayed to others.'
                    "
                  />
                </div>
              </div>
              <div class="row mb-4" v-if="form.clinic_name">
                <div class="col">
                  <input
                    type="text"
                    v-model="form.clinic_email"
                    class="form-control"
                    placeholder="Clinic email (display)"
                    @change="checkEmail(form.clinic_email)"
                    v-tooltip:bottom="
                      'Your clinic\'s email address as displayed to others.'
                    "
                  />
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col">
                  <h4 class="mb-3">Your Details</h4>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
                  <input
                    type="text"
                    v-model="form.first_name"
                    class="form-control"
                    placeholder="First name"
                    required
                  />
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
                  <input
                    type="text"
                    v-model="form.last_name"
                    class="form-control"
                    placeholder="Last name"
                    required
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-6 mb-3 mb-sm-3">
                  <input
                    type="email"
                    id="email"
                    v-model="form.email"
                    class="form-control"
                    placeholder="Email Address"
                    @change="checkEmail(form.email)"
                    v-tooltip:bottom="
                      'The email address you will use to log in and receive communications via.'
                    "
                  />
                </div>
                <div class="col-12 col-sm-6 mb-3">
                  <phone-number-input
                    v-model="form.phone"
                    default-country-code="GB"
                    :preferred-countries="[
                      'GB',
                      'MT',
                      'GR',
                      'ES',
                      'FR',
                      'BE',
                      'DE',
                    ]"
                    :clearable="true"
                  ></phone-number-input>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col text-center">
                  <input
                    type="submit"
                    class="btn btn-primary"
                    :disabled="!formValid || busy || emailInUse"
                    :value="busy ? 'Please wait...' : 'Next'"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="alreadyRegisteredModal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row">
              <div class="col text-center">
                <p>You have already registered for Swandoola!</p>
                <p>
                  To view the webinar you can login into your existing Swandoola
                  account using your email address by following the link below.
                  You can also download and watch via the Mobile App.
                </p>
                <a
                  class="btn btn-primary btn-lg"
                  href="https://app.swandoola.com/login"
                >
                  Login to Swandoola
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
  data() {
    return {
      busy: false,
      success: false,
      form: {
        clinic_name: null,
        clinic_email: null,
        first_name: null,
        last_name: null,
        email: null,
        referrer: -1,
      },
      emailInUse: false,
    };
  },
  watch: {},
  methods: {
    submitForm() {
      this.busy = true;
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/onboard", this.form)
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.busy = false;
          if (data.color !== "danger") {
            this.success = true;
            this.$emit("userAdded", data.user);
          }
        });
    },
    checkEmail(email) {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/check-email-exists", {
          email: email,
        })
        .then(({ data }) => {
          this.emailInUse = data;
          if (this.emailInUse) {
            $("#alreadyRegisteredModal").modal("show");
          }
        });
    },
  },
  mounted() {
    $("#successModal").on("hide.bs.modal", (e) => {
      this.$emit("close");
    });
  },
  computed: {
    formValid() {
      return this.form.first_name && this.form.last_name && this.form.email;
    },
  },
  filters: {},
};
</script>

<style>
</style>

<template>
  <div>
    <div class="row" v-if="!showOnboard && !showUpgrade">
      <div class="col m-auto">
        <div class="card">
          <div class="card-body text-center">
            <div class="row mb-3 mt-2">
              <div class="col text-center">
                <img
                  src="@/assets/images/brand/navy-gold-med.png"
                  alt="Swandoola"
                  width="100"
                />
              </div>
            </div>
            <div class="row mb-4">
              <div class="col text-center">
                <h1>{{ media.room.title }} - {{ media.title }}</h1>
              </div>
            </div>
            <hr />
            <p class="alert alert-dark" style="border-radius: 0px; border: 0px" v-if="!media.room.user_has_access">
              <i class="far fa-info-circle me-1"></i>
              You do not currently have access to this content.
              <span v-if="media.room.price">
                This content is available to purchase as part of {{media.room.title}} for £{{media.room.price.toFixed(2)}}.
                <br />
                It is also available free to Swandoola Platinum Members
              </span>
            </p>
            <h2 class="mb-3 mt-4" v-if="!media.room.price">
              This webinar is available to
              <span class="text-capitalize">{{
                media.room.package_constraint
              }}</span>
              users only.
            </h2>
            <router-link :to="'/webinars/' + media.room.id + '/purchase'" v-if="media.room.price" class="btn btn-primary btn-lg">
              Purchase now for <b>£{{media.room.price.toFixed(2)}}</b>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Onboard from "./Onboard";
import UpcomingInvoice from "../../billing/areas/subscription-partials/UpcomingInvoice";
export default {
  props: ["user", "media", "stripeKey", "billingProduct"],
  data() {
    return {
      stripe: Stripe(this.stripeKey),
      loading: true,
      busy: false,
      busySpending: false,
      showOnboard: false,
      showUpgrade: false,
      period: "monthly",
      paymentMethod: "newCard",
      name: "",
      cardElement: null,
      invoiceBlueprint: null,
    };
  },
  computed: {
    selectedPlan() {
      const vm = this;
      return this.billingProduct.billing_plans.filter(function (plan) {
        return plan.billing_period === vm.period && plan.user_role === "owner";
      })[0];
    },
    unusedTokens() {
      return this.user.webinar_tokens.filter(function (token) {
        return !(token.room_media_id > 0);
      });
    },
  },
  methods: {
    userAdded(data) {
      this.user = data;
      this.showOnboard = false;
      this.showUpgrade = true;
      this.initForUser();
      console.log(data);
    },
    initForUser() {
      this.fetchInvoiceBlueprint().then(() => {
        this.cardElement = this.createCardElement("#payment-card-element");
      });
    },
    processPayment() {
      this.busy = true;
      if (this.paymentMethod === "newCard") {
        this.processNewCardPayment();
      } else {
        this.processCardPayment(this.paymentMethod, false);
      }
    },
    processCardPayment(paymentMethod, paymentMethodIsNew) {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/billing/payment-card", {
          name_on_card: this.name,
          billing_plan_id: this.selectedPlan.id,
          payment_method: paymentMethod,
          payment_method_is_new: paymentMethodIsNew,
          coupon_id: this.approvedCoupon ? this.approvedCoupon.id : null,
        })
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          const invoice_id = data.stripe_invoice_id;
          if (data.state === "action_required") {
            const vm = this;
            this.stripe
              .confirmCardPayment(data.client_secret)
              .then(function (result) {
                if (result.error) {
                  // Display error.message in your UI.
                  vm.busy = false;
                  this.$EventBus.$emit("alert", {
                    message: result.error.message,
                    color: "danger",
                  });
                  // cancel invoice and revert subscription change as payment failed
                  this.$axios.post(
                    process.env.VUE_APP_API_URL + "/billing/payment-sca-failed",
                    {
                      invoice_id: invoice_id,
                      original_plan_id:
                        vm.user.clinic.billing_subscription.billing_plan
                          .stripe_plan_id,
                    }
                  );
                } else {
                  vm.busy = false;
                  this.$EventBus.$emit("alert", {
                    message: "Payment successful!",
                  });
                  setTimeout(() => {
                    window.location =
                      "/webinars/" +
                      this.media.room.id +
                      "/media/" +
                      this.media.id;
                  }, 5000);
                }
              });
          } else {
            if (data.color !== "danger") {
              setTimeout(() => {
                window.location =
                  "/webinars/" + this.media.room.id + "/media/" + this.media.id;
              }, 5000);
            } else {
              this.busy = false;
            }
          }
        })
        .catch(function (error) {
          this.$EventBus.$emit("alert", {
            color: "danger",
            message: "Something went wrong - Payment has not been processed.",
          });
          console.log(error);
        });
    },
    processNewCardPayment() {
      this.stripe
        .createPaymentMethod({
          type: "card",
          card: this.cardElement,
          billing_details: {
            name: this.name,
          },
        })
        .then((result) => {
          if (result.error) {
            self.hasCardErrors = true;
            this.busy = false;
          } else {
            this.processCardPayment(result.paymentMethod, true);
          }
        });
    },
    fetchInvoiceBlueprint() {
      return this.$axios
        .post(process.env.VUE_APP_API_URL + "/billing/invoice-blueprint", {
          billing_plan_id: this.selectedPlan.id,
          coupon_id: null,
        })
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.invoiceBlueprint = data.invoice;
        });
    },
    createCardElement(container) {
      if (!this.stripe) {
        throw "Invalid Stripe Key/Secret";
      }

      var card = this.stripe.elements().create("card", {
        // hideIcon: true,
        // hidePostalCode: true,
        style: {
          base: {
            "::placeholder": {
              color: "#aab7c4",
            },
            fontFamily:
              'Whitney, Lato, -apple-system, BlinkMacSystemFont,"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji","Segoe UI Emoji", "Segoe UI Symbol"',
            color: "#495057",
            fontSize: "15px",
          },
        },
      });

      card.mount(container);
      this.loading = false;
      return card;
    },
    spendToken() {
      this.busySpending = true;
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/webinars/spend-token", {
          webinar_token_id: this.unusedTokens[0].id,
          room_media_id: this.media.id,
        })
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          setTimeout(() => {
            window.location =
              "/webinars/" + this.media.room.id + "/media/" + this.media.id;
          }, 2000);
        });
    },
  },
  watch: {
    selectedPlan: function (val) {
      if (val) {
        this.fetchInvoiceBlueprint();
      } else {
        this.invoiceBlueprint = null;
      }
    },
  },
  mounted() {
    if (this.user.id) {
      this.initForUser();
    }
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
  },
  components: { UpcomingInvoice, Onboard },
};
</script>

<template>
  <div>
    <div class="container-fluid" v-if="media">
      <div v-if="userIsAllowedToWatch">
        <div class="row mb-4">
          <div class="mx-auto" :class="media.streaming ? 'col-md-8' : 'col'">
            <div class="card border-0 shadow-sm bg-light">
              <div class="card-header bg-white">
                <div class="d-flex">
                  <h5 class="my-auto">{{ media.title }}</h5>
                  <router-link
                      v-if="media.room.user_is_host"
                      :to="'/webinars/' + this.media.room.id + '/media/' + this.media.id + '/studio'"
                      target="_blank"
                      class="btn btn-success ms-auto">
                    <i class="far fa-video me-1"></i>
                    Launch Webinar Host Studio
                  </router-link>
                </div>
              </div>
              <div class="card-body">
                <div class="row" ref="mainVideo">
                  <div class="col-md-10 mx-auto">
                    <video
                      ref="streamVideo"
                      controls
                      autoplay
                      width="100%"
                      class="bg-black stream"
                      v-if="!media.uploaded_media"
                    ></video>
                    <div v-if="media.uploaded_media">
                      <div :class="!webinarStarted ? 'd-none' : ''">
                        <i v-if="!noControlsPlaying"
                           class="far fa-play-circle text-primary fa-8x cursor-pointer"
                           style="position: absolute; left: 48%; top: 40%; z-index: 9999"
                           @click="playNoControls"></i>
                        <video
                            ref="vodVideo"
                            :src="media.uploaded_media"
                            :controls="!hideControls"
                            width="100%"
                            class="bg-black vod"
                            :style="!noControlsPlaying ? 'opacity: 0.3' : ''"
                        ></video>
                      </div>
                      <div v-if="!webinarStarted">
                        <div class="row my-4">
                          <div class="col text-center">
                            <i class="far fa-clock fa-5x text-primary mb-4" />
                            <h5>
                              Webinar starts at {{media.starts_at | formatDate}}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4" v-if="media.streaming">
            <chat-area
              :user="user"
              :room-id="$route.params.id"
              :media-id="$route.params.mediaId"
              :api-url="apiUrl"
            ></chat-area>
          </div>
        </div>

        <div class="row mb-4" v-if="media.worksheets.length > 0">
          <div class="col">
            <div class="card border-0 shadow-sm bg-light">
              <div class="card-header bg-white">
                <h4 class="mb-0">Worksheets</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-2" v-for="a in media.worksheets" :key="a.id">
                    <a :href="a.url" target="_blank">
                      <div class="card border-0">
                        <div class="card-body text-center">
                          <h5 class="mb-3">{{ a.title }}</h5>
                          <a :href="a.url" class="btn btn-primary">View</a>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="media.slides.length > 0">
          <div class="col">
            <div class="card border-0 shadow-sm bg-light">
              <div class="card-header bg-white">
                <h4 class="mb-0">Slides</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-2" v-for="a in media.slides" :key="a.id">
                    <a :href="a.url" target="_blank">
                      <div class="card border-0">
                        <div class="card-body text-center">
                          <h5 class="mb-3">{{ a.title }}</h5>
                          <a :href="a.url" class="btn btn-primary">View</a>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="row">
          <div class="col">
            <onboard-platinum
              :media="media"
              :user="user"
              :billing-product="billingProduct"
              :stripe-key="stripeKey"
            ></onboard-platinum>
          </div>
        </div>
      </div>

      <!--  -->
    </div>

    <div v-else>
      <busy>Loading video...</busy>
    </div>
  </div>
</template>

<script>

import ChatArea from "./view-partials/ChatArea";
import OnboardPlatinum from "./view-partials/OnboardPlatinum";
// import Hls from "hls.js";
const Hls = require("hls.js");

export default {
  data() {
    return {
      broadcastUrl: "https://broadcast.swandoola.com/streaming",
      media: null,
      billingProduct: null,
      noControlsPlaying: false,
      webinarStarted: false,
    };
  },
  computed: {
    stripeKey() {
      return process.env.VUE_APP_STRIPE_KEY;
    },
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
    userIsAllowedToWatch() {
      return (this.media.room.user_has_access || this.media.room.user_is_host);
    },
    userHasWebinarToken() {
      //check the user has redeemed a token that would allow them to watch
      //this means they have already redeemed a token against this particular webinar
      let redeemed = false;
      this.user.webinar_tokens.forEach((token) => {
        if (token.room_media_id === this.$route.params.mediaId) {
          redeemed = true;
        }
      });
      return redeemed;
    },
    userPackageMeetsConstraint() {
      //check the user's billing package matches the constraint
      if (this.media.room.package_constraint) {
        return (
          this.media.room.package_constraint ===
          this.user.clinic.billing_subscription.billing_product.title.toLowerCase()
        );
      } else {
        return true;
      }
    },
    hideControls() {
      return false;
    }
  },
  watch: {
    webinarStarted() {
      if (this.webinarStarted && this.hideControls) {
        this.playNoControls();
      }
    }
  },
  methods: {
    async fetchMedia() {
      await this.$axios
        .get(this.apiUrl + "/edu/rooms/media/" + this.$route.params.mediaId)
        .then(({ data }) => {
          this.media = data;
        });
      return "got data";
    },
    async fetchBillingProduct() {
      await this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/billing/fetch-platinum-billing-product"
        )
        .then(({ data }) => {
          this.billingProduct = data;
        });
      return "got data";
    },
    setupStreamPlayer() {
      if (Hls.isSupported()) {
        var config = {
          initialLiveManifestSize: 4,
        };
        const hls = new Hls(config);
        hls.loadSource(
          this.broadcastUrl + "/" + this.$route.params.mediaId + "/stream.m3u8"
        );

        hls.attachMedia(this.$refs.streamVideo);

        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          this.$refs.streamVideo.play();
          // this.$refs.streamVideo.onplay(() => {
          //   console.log("playing");
          // });
        });
      } else if (
        this.$refs.streamVideo.canPlayType("application/vnd.apple.mpegurl")
      ) {
        this.$refs.streamVideo.src =
          this.broadcastUrl + "/" + this.$route.params.mediaId + "/stream.m3u8";
        this.$refs.streamVideo.addEventListener("loadedmetadata", () => {
          this.$refs.streamVideo.play();
        });
      }
    },
    playNoControls() {
      this.noControlsPlaying = true;
      const vm = this;
      this.$refs.vodVideo.play().catch(function(error) {
        vm.noControlsPlaying = false;
      })
    }
  },
  created() {
    Promise.all([this.fetchMedia(), this.fetchBillingProduct()]).then(() => {
      if (!this.media.uploaded_media) {
        this.setupStreamPlayer();
      } else {
        this.webinarStarted = moment().isAfter(moment.utc(this.media.starts_at));

        if (!this.webinarStarted) {
          const vm = this;
          setInterval(function() {
            if (!vm.webinarStarted){
              vm.webinarStarted = moment().isAfter(moment.utc(vm.media.starts_at));
            }
          }, 2000);
        }
      }
    });
  },
  filters: {
    formatDate(date) {
      return moment.utc(date).local().format("LLL");
    }
  },
  components: {
    OnboardPlatinum,
    ChatArea,
  },
};
</script>

<style>
</style>

import { firebase } from "@firebase/app"
import '@firebase/firestore'
import '@firebase/storage'


const firebaseConfig = {
  apiKey: "AIzaSyA4r_tvkia29kJrrXptBPWBbVqm0CSlc7Q",
  authDomain: "swandoola-room-chat.firebaseapp.com",
  databaseURL: "https://swandoola-room-chat.firebaseio.com",
  projectId: "swandoola-room-chat",
  storageBucket: "swandoola-room-chat.appspot.com",
  messagingSenderId: "48535410085",
  appId: "1:48535410085:web:7a3f3077f634f04f52c44c"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
export {
  firebase,
  db
}

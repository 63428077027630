<template>
  <div class="chat-area" ref="chatArea">
    <div class="card border-0 shadow bg-light">
      <div class="card-header bg-white">
        <h5 class="mb-0">Questions</h5>
      </div>
      <div
        class="card-body"
        style="height: 65vh; overflow-y: scroll"
        ref="chatAreaCardBody"
      >
        <!--  -->
        <div class="row" v-for="m in messages" :key="m.id">
          <div
            class="col-md-10"
            :class="m.from === user.email ? 'ms-auto' : 'me-auto'"
          >
            <div
              class="alert"
              :class="m.from === user.email ? 'alert-dark' : 'alert-primary'"
            >
              {{ m.body }}
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col">
            <textarea
              rows="2"
              v-model="message.body"
              class="form-control"
              placeholder="Send a message..."
            ></textarea>
          </div>
          <div class="col-auto my-auto">
            <button class="btn btn-sm btn-primary" @click="sendMessage">
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { firebase, db } from "./firebaseinit";

export default {
  props: ["user", "mediaId", "roomId", "apiUrl"],
  data() {
    return {
      from: [],
      to: [],
      message: {
        body: "",
        date: new Date(),
        from: this.user.email,
        to: "hello@swandoola.com",
        name: this.user.name,
      },
    };
  },
  computed: {
    messages() {
      let messagesList = this.to.concat(this.from);
      messagesList.sort((b, a) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      if (messagesList.length == 0) {
        return [
          {
            body:
              "Have a question or comment? Add it here and a member of the team will respond as soon as possible. This is a private conversation.",
            date: new Date(),
            from: "hello@swandoola.com",
            to: this.user.email,
          },
        ];
      }
      return messagesList;
    },
  },
  methods: {
    startChat() {
      this.fetchTo();
      this.fetchFrom();
    },
    fetchTo() {
      var messagesRef = db
        .collection("rooms")
        .doc(this.mediaId)
        .collection("messages")
        .where("to", "==", this.user.email)
        .onSnapshot((ref) => {
          var messages = [];
          ref.docs.forEach((message) => {
            var data = message.data();
            data.id = message.id;
            data.created_at = data.date.toDate();
            messages.push(data);
          });

          this.to = messages;

          this.$nextTick(() => {
            this.scrollToEnd();
          });
        });
    },
    fetchFrom() {
      var messagesRef = db
        .collection("rooms")
        .doc(this.mediaId)
        .collection("messages")
        .where("from", "==", this.user.email)
        .onSnapshot((ref) => {
          var messages = [];
          ref.docs.forEach((message) => {
            var data = message.data();
            data.id = message.id;
            data.created_at = data.date.toDate();
            messages.push(data);
          });

          this.from = messages;

          this.$nextTick(() => {
            this.scrollToEnd();
          });
        });
    },

    sendMessage() {
      db.collection("rooms")
        .doc(this.mediaId)
        .collection("messages")
        .add({ ...this.message });

      this.message = {
        body: "",
        date: new Date(),
        from: this.user.email,
        to: "hello@swandoola.com",
        name: this.user.name,
      };

      this.$nextTick(() => {
        this.scrollToEnd();
      });
    },

    scrollToEnd() {
      this.$refs.chatAreaCardBody.scrollTop = this.$refs.chatAreaCardBody.scrollHeight;
    },
  },
  mounted() {
    this.startChat();
  },
};
</script>

<style>
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_vm._m(0),_vm._m(1),_c('hr'),_vm._m(2),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"row",class:_vm.form.clinic_name ? 'mb-3' : 'mb-4'},[_c('div',{staticClass:"col"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.clinic_name),expression:"form.clinic_name"},{name:"tooltip",rawName:"v-tooltip:bottom",value:(
                    'Your clinic\'s name as it should be displayed to others.'
                  ),expression:"\n                    'Your clinic\\'s name as it should be displayed to others.'\n                  ",arg:"bottom"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Clinic name (optional)"},domProps:{"value":(_vm.form.clinic_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "clinic_name", $event.target.value)}}})])]),(_vm.form.clinic_name)?_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.clinic_email),expression:"form.clinic_email"},{name:"tooltip",rawName:"v-tooltip:bottom",value:(
                    'Your clinic\'s email address as displayed to others.'
                  ),expression:"\n                    'Your clinic\\'s email address as displayed to others.'\n                  ",arg:"bottom"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Clinic email (display)"},domProps:{"value":(_vm.form.clinic_email)},on:{"change":function($event){return _vm.checkEmail(_vm.form.clinic_email)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "clinic_email", $event.target.value)}}})])]):_vm._e(),_c('hr'),_vm._m(3),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-6 mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.first_name),expression:"form.first_name"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"First name","required":""},domProps:{"value":(_vm.form.first_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "first_name", $event.target.value)}}})]),_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-6 mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.last_name),expression:"form.last_name"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Last name","required":""},domProps:{"value":(_vm.form.last_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "last_name", $event.target.value)}}})])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-6 mb-3 mb-sm-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"},{name:"tooltip",rawName:"v-tooltip:bottom",value:(
                    'The email address you will use to log in and receive communications via.'
                  ),expression:"\n                    'The email address you will use to log in and receive communications via.'\n                  ",arg:"bottom"}],staticClass:"form-control",attrs:{"type":"email","id":"email","placeholder":"Email Address"},domProps:{"value":(_vm.form.email)},on:{"change":function($event){return _vm.checkEmail(_vm.form.email)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}})]),_c('div',{staticClass:"col-12 col-sm-6 mb-3"},[_c('phone-number-input',{attrs:{"default-country-code":"GB","preferred-countries":[
                    'GB',
                    'MT',
                    'GR',
                    'ES',
                    'FR',
                    'BE',
                    'DE' ],"clearable":true},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1)]),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-center"},[_c('input',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":!_vm.formValid || _vm.busy || _vm.emailInUse},domProps:{"value":_vm.busy ? 'Please wait...' : 'Next'}})])])])])])])]),_vm._m(4)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mb-2 mt-2"},[_c('div',{staticClass:"col text-center"},[_c('img',{attrs:{"src":require("@/assets/images/brand/navy-gold-med.png"),"alt":"Swandoola","width":"100"}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-center"},[_c('h2',[_vm._v("Swandoola Practitioners")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h4',{staticClass:"mb-3"},[_vm._v("Clinic Details")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h4',{staticClass:"mb-3"},[_vm._v("Your Details")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal fade",attrs:{"id":"alreadyRegisteredModal","tabindex":"-1","role":"dialog","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-lg modal-dialog-centered",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-center"},[_c('p',[_vm._v("You have already registered for Swandoola!")]),_c('p',[_vm._v(" To view the webinar you can login into your existing Swandoola account using your email address by following the link below. You can also download and watch via the Mobile App. ")]),_c('a',{staticClass:"btn btn-primary btn-lg",attrs:{"href":"https://app.swandoola.com/login"}},[_vm._v(" Login to Swandoola ")])])])])])])])}]

export { render, staticRenderFns }